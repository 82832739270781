import React from 'react';


// Icons import
import DashboardIcon from '@mui/icons-material/Dashboard';

const Home = React.lazy(() => import('./pages/user/home/home'));
const AboutUs = React.lazy(() => import('./pages/user/aboutus/'));
const ContactUs = React.lazy(() => import('./pages/user/contactus/'));
const Stocks = React.lazy(() => import('./pages/user/stocks/'));

const Profile = React.lazy(() => import('./pages/user/profile/profile'));
const OurPartners = React.lazy(() => import('./pages/user/ourpartners/ourpartners'));
const Search = React.lazy(() => import('./pages/user/search/search'));


// Admin pages import
const AdminLogin = React.lazy(() => import('./pages/admin/adminlogin/adminlogin'));
const Dashboard = React.lazy(() => import('./pages/admin/dashboard/dashboard'));



export const userRoutes = [{
    path: '/',
    text: 'Home',
    component: Home
},
{
    path: '/aboutus',
    text: 'About Us',
    component: AboutUs
},
{
    path: '/partnerwithus',
    text: 'Our Partners',
    component: OurPartners
},
{
    path: '/tickers',
    text: 'Stock List',
    component: Stocks
},
{
    path: '/contact',
    text: 'Contact Us',
    component: ContactUs
},
{
    path: '/profile',
    text: 'Profile',
    component: Profile
}

];

export const adminRoutes = [
    {
        path: '/admin/login',
        text: 'Login',
        isAdmin: true,
        hidden: true,
        component: AdminLogin
    },
    {
        path: '/admin',
        text: 'Dashboard',
        isAdmin: true,
        icon: DashboardIcon,
        component: Dashboard
    }
]

export const allRoutes = [...userRoutes, ...adminRoutes];

function getFlatComponent() {
    let temp = [];
    for (let path of allRoutes) {
        temp.push(path);
        if (path.children) {
            temp.push(path.children);
        }
    }
    return temp.flat(Infinity).filter(itm => itm.path !== undefined && itm.component !== undefined);
}
export const activeRoutes = getFlatComponent();
export default allRoutes;