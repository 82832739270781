import React, { useState, useEffect, forwardRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Dialog,
  DialogContent,
  Toolbar,
  AppBar,
  Typography,
  Slide,
} from "@mui/material";
import { useSelector } from "react-redux";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import BookIcon from '@mui/icons-material/Book';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import ForumIcon from '@mui/icons-material/ForumOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import useAuth from "../../../../customhooks/user/useauth";
import useInfo from "../../../../customhooks/user/useinfo";
import { encryptStorage } from "../../../../utils/storage";
import logo from "../../../../assets/logos/logo.png";
import Login from "../../../../components/login/login";
import Notification from "../../../../components/notification/notification";

import './navdrawer.css';

// Slide transition for Dialog
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NavDrawer() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fullName, setFullName] = useState('');
  const [isLogin, setLogin] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const auth = useAuth();
  const userInfo = useInfo();
  const navigate = useNavigate();
  const isLoginSignup = useSelector(state => state.login.isLoginSignup);
  const query = new URLSearchParams(location.search);

  const handleClose = () => setLogin(false);

  useEffect(() => {
    setOpenDrawer(false);
    if (userInfo) {
      setFullName(`${userInfo.firstName} ${userInfo.lastName}`);
    }
  }, [location.pathname, userInfo]);

  const openChatWithUs = () => window.open('https://wa.me/919090951010');

  const navigateMobile = (path) => {
    setOpenDrawer(false);
    navigate(path);
  };

  useEffect(() => {
    if (isLoginSignup) setLogin(true);
  }, [isLoginSignup]);

  useEffect(() => {
    const temp = query.get("isMobile") || localStorage.getItem("isMobile");
    if (temp) {
      localStorage.setItem('isMobile', true);
      setIsMobile(true);
    }
  }, [query]);

  return (
    <>
      {auth && <Box className={`${isMobile ? 'mobile-notification mobile-top' : 'mobile-notification'}`}>
        {/* <Notification /> */} <div>Notification</div>
      </Box>}

      <Dialog open={openDrawer} TransitionComponent={Transition} fullScreen>
        <AppBar sx={{ position: 'relative', padding: 0 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setOpenDrawer(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Price Algo</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                if (!auth) {
                  setLogin(true);
                  setOpenDrawer(false);
                  return;
                }
                encryptStorage.clear();
                setOpenDrawer(false);
                navigateMobile('/');
              }}
              aria-label="close"
            >
              <PowerSettingsNewOutlinedIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent sx={{ px: 0 }}>
          <List className="animate__slideInRight animate__animated" sx={{ p: 0 }}>
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/')}>
                <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
                <ListItemText>Home</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/photogallery')}>
                <ListItemIcon><PhotoLibraryIcon /></ListItemIcon>
                <ListItemText>Event Gallery</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/about')}>
                <ListItemIcon><InfoOutlinedIcon /></ListItemIcon>
                <ListItemText>About</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/members')}>
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText>Members</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/contact')}>
                <ListItemIcon><PhoneIcon /></ListItemIcon>
                <ListItemText>Contact</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemButton onClick={() => navigateMobile('/fee-structure')}>
                <ListItemIcon><BookIcon /></ListItemIcon>
                <ListItemText>Blog</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider />
            {
              !auth ? (
                <>
                  <ListItem>
                    <ListItemButton onClick={() => { setLogin(true); setOpenDrawer(false); }}>
                      <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
                      <ListItemText>Login/Signup</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              ) : (
                <>
                  <ListItem>
                    <ListItemButton onClick={() => navigateMobile('/search')}>
                      <ListItemIcon><PersonSearchIcon /></ListItemIcon>
                      <ListItemText>Search</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton onClick={() => navigateMobile('/forum')}>
                      <ListItemIcon><CampaignOutlinedIcon /></ListItemIcon>
                      <ListItemText>Forum</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton onClick={() => navigateMobile('/profile')}>
                      <ListItemIcon><AccountCircleOutlinedIcon /></ListItemIcon>
                      <ListItemText>
                        Profile <span style={{ fontSize: '11px' }}>{`(${fullName})`}</span>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem onClick={openChatWithUs}>
                    <ListItemButton>
                      <ListItemIcon><ForumIcon /></ListItemIcon>
                      <ListItemText>Chat With Us</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemButton onClick={() => {
                      encryptStorage.clear();
                      setOpenDrawer(false);
                      navigateMobile('/');
                    }}>
                      <ListItemIcon><PowerSettingsNewOutlinedIcon /></ListItemIcon>
                      <ListItemText>Logout</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              )
            }
          </List>
        </DialogContent>
      </Dialog>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="img-logo" style={{ height: "30px" }} />
        </Box>
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Login open={isLogin} close={handleClose} />
    </>
  );
}

export default NavDrawer;
